$(function(){
	$('a[href*=\\#]:not([href=\\#])').click(function() {
	if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var $target = $(this.hash);
			$target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
			if ($target.length) {
				if($(this).parents('.menuBox').length){
					setTimeout(function(){
						var targetOffset = $target.offset().top;
						$('html,body').animate({scrollTop: targetOffset}, 1000);
					},100);
				}else{
					var targetOffset = $target.offset().top;
					$('html,body').animate({scrollTop: targetOffset}, 1000);
				}
				return false;
			}
		}
	});
	if($(window).width() < 897){
		$('#gFooter .snsUl').insertAfter($('#gFooter .rightBox'));
	}
	
	var state = false;
	var scrollpos;
	$('#gHeader .menu').on('click', function() {
		if (state == false) {
			scrollpos = $(window).scrollTop();
			$('body').addClass('fixed').css({ 'top': -scrollpos });
			$('.menuBox').stop().fadeToggle();
			state = true;
		} else {
			$('body').removeClass('fixed').css({ 'top': 0 });
			window.scrollTo(0, scrollpos);
			$('.menuBox').stop().fadeToggle();
			state = false;
		}
		return false;
	});
	
	$('.menuBox .close,.menuBox .naviUl a').click(function(){
		$('body').removeClass('fixed').css({ 'top': 0 });
		window.scrollTo(0, scrollpos);
		$('.menuBox').stop().fadeToggle();
		state = false;
	});
	if($('#about').length){
		$('#about .pointUl .title').matchHeight();
	}
});

$(window).on('load',function(){
	var localLink = window.location+'';
	if(localLink.indexOf("#") != -1 && localLink.slice(-1) != '#'){	
		localLink = localLink.slice(localLink.indexOf("#")+1);
		$('html,body').animate({scrollTop: $('#'+localLink).offset().top}, 500);
	}
});
